import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import endOfYear from 'date-fns/endOfYear';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import { DestroySubscriptionsService } from '@libs/src/destroy-subscription/destroy-subscription.service';
import { ToastService } from '@libs/src/toast/toast.service';
import { WindowRefService } from '@main-client/src/app/window-ref.service';

import { environment } from '@libs/src/environments/environment';
import { getServerError } from '@libs/src/error-handler/error-handler.helper';
import { isSupportedLocale } from '@libs/src/locale/locale.helper';
import {
  defaultLocaleCode,
  SupportedLocaleCode,
} from '@libs/src/locale/locale.shared';
import { Group } from '@libs/src/models/group.model';
import { Tenant } from '@libs/src/tenant/tenant.interface';
import { IAppState } from '@main-client/src/app/app.state';
import { currentGroupSource } from '@main-client/src/app/group-view/group-view.helper';

import attempt from 'lodash-es/attempt';
import defer from 'lodash-es/defer';
import isEmpty from 'lodash-es/isEmpty';
import isError from 'lodash-es/isError';

@Injectable({ providedIn: 'root' })
export class LocaleSettingsService {
  readonly localeCookieKey = 'ttc_locale_settings';
  readonly toughCookieParserJsonPrefix = 'j:';

  constructor(
    @Inject(LOCALE_ID) public appLocale: string,
    public cookieService: CookieService,
    public windowService: WindowRefService,
    private readonly store: Store<IAppState>,
    private readonly toastService: ToastService,
  ) {}

  getLocaleCookie(): {
    locale_selected: SupportedLocaleCode;
  } {
    const value = attempt(() => {
      const cookieValue = this.cookieService.get(this.localeCookieKey);
      if (!cookieValue) {
        return;
      }
      const sanitizedCookieValue = cookieValue.replace(
        this.toughCookieParserJsonPrefix,
        '',
      );
      return JSON.parse(sanitizedCookieValue);
    });
    return isError(value) ? '' : value;
  }

  setLocaleCookie(locale: SupportedLocaleCode) {
    this.cookieService.set(
      this.localeCookieKey,
      this.generateLocaleCookieValue(locale),
      { expires: new Date(endOfYear(new Date('2099'))), path: '/' },
    );
  }

  deleteLocaleCookie() {
    this.cookieService.delete(this.localeCookieKey);
    this.cookieService.delete(this.localeCookieKey, '/');
  }

  generateLocaleCookieValue(localeSelected: string) {
    const value = this.generateLocaleConfig(localeSelected);
    return `${this.toughCookieParserJsonPrefix}${JSON.stringify(value)}`;
  }

  generateLocaleConfig(localeSelected: string) {
    return {
      last_modified_date: new Date(Date.now()),
      locale_selected: localeSelected,
    };
  }

  ensureBrowserLocale(tenant: Tenant, userLocaleCode: SupportedLocaleCode) {
    if (!environment.production) {
      return;
    }
    const localeCookieSelected = this.getLocaleCookie()?.locale_selected;
    const userLocaleSelected = userLocaleCode;
    const isLocalizationEnabled = tenant.enabled_features?.localization;
    if (!isLocalizationEnabled && localeCookieSelected !== defaultLocaleCode) {
      this.deleteLocaleCookie();
      this.setLocaleCookie(defaultLocaleCode);
      if (this.appLocale !== defaultLocaleCode) {
        defer(() => this.windowService.nativeWindow.location.reload());
      }
      return;
    }
    if (
      isLocalizationEnabled &&
      userLocaleSelected &&
      isSupportedLocale(userLocaleSelected) &&
      userLocaleSelected !== this.appLocale
    ) {
      this.deleteLocaleCookie();
      this.setLocaleCookie(userLocaleSelected);
      defer(() => this.windowService.nativeWindow.location.reload());
    }
  }

  isLocalizationEnabledForHub(
    componentDestroy: DestroySubscriptionsService,
  ): Observable<boolean> {
    const group$ = currentGroupSource(this.store, componentDestroy);
    const tenant$ = this.store.select('tenant').pipe(
      filter((tenant: Tenant) => !isEmpty(tenant)),
      take(1),
    );
    return combineLatest([group$, tenant$]).pipe(
      map(
        ([group, tenant]: [Group, Tenant]) =>
          group.features_enabled?.localization &&
          tenant.enabled_features?.localization,
      ),
      catchError((response: HttpErrorResponse) => {
        this.toastService.showError(getServerError(response));
        return EMPTY;
      }),
      take(1),
    );
  }
}
