import { NgModule } from '@angular/core';

import { UserMutationService } from './user-mutation.service';
import { UserService } from './user.service';
import { UserAuthService } from '@libs/src/services/user-auth.service';

@NgModule({
  providers: [UserAuthService, UserMutationService, UserService],
})
export class UserModule {}
