import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { EmailVerificationDialogModule } from '@main-client/src/app/email-verification-dialog/email-verification-dialog.module';

import { AccountAutoCompleteService } from './account-autocomplete.service';
import { AnyAdminGuardService } from './any-admin-guard.service';
import { AuthGuardService } from './auth-guard.service';
import { GeocodeService } from './geocode.service';
import { GroupMembershipService } from './group-membership.service';
import { LocationService } from './location.service';
import { RouteListenerService } from './route-listener.service';
import { TenantAdminGuardService } from './tenant-admin-guard.service';
import { UpdatesService } from './updates.service';
import { AdminLegacyGuardService } from '@main-client/src/app/admin/admin-legacy-guard.service';
import { CrossTabListenerService } from '@main-client/src/app/core/cross-tab-listener.service';
import { GroupAdminGuardService } from '@main-client/src/app/core/group-admin-guard.service';
import { LocalStorageService } from '@main-client/src/app/core/local-storage.service';

import { NoAuthGuard } from './no-auth.guard';
import { RequestInterceptor } from './request.interceptor';
import { HasGroupsGuard } from '@main-client/src/app/core/has-groups.guard';
import { NonRootGuard } from '@main-client/src/app/core/non-root.guard';

@NgModule({
  imports: [CommonModule, EmailVerificationDialogModule],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
    },
    AccountAutoCompleteService,
    AdminLegacyGuardService,
    TenantAdminGuardService,
    AnyAdminGuardService,
    AuthGuardService,
    CrossTabListenerService,
    GeocodeService,
    GroupAdminGuardService,
    GroupMembershipService,
    HasGroupsGuard,
    LocationService,
    LocalStorageService,
    NoAuthGuard,
    NonRootGuard,
    RouteListenerService,
    Title,
    UpdatesService,
  ],
})
export class CoreModule {}
