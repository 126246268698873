import { Injectable } from '@angular/core';

const DEFAULT_HOST = 'www.tenthousandcoffees.com';

function _window(): any {
  return window
    ? window
    : {
        location: { host: DEFAULT_HOST },
        zE: (x: string, y: string) => `${x}, ${y}`,
      };
}

// TODO: https://github.com/tenthousandcoffees/web-client/issues/7186
@Injectable({ providedIn: 'root' })
export class WindowRefService {
  get nativeWindow(): Window & { zE: any } {
    return _window();
  }
}
