import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AnalyticsService } from '@libs/src/analytics/analytics.service';
import { LoggerService } from '@libs/src/logger/logger.service';
import { PostHogService } from '@libs/src/posthog/post-hog.service';
import {
  AUTH_TOKEN_KEY,
  LocalStorageService,
  REFRESH_TOKEN_KEY,
} from '@main-client/src/app/core/local-storage.service';

import type {
  LoginResponse,
  LogInTokenCredentials,
} from '@libs/src/interfaces/login-response.interface';

import find from 'lodash-es/find';
import get from 'lodash-es/get';

const LOGIN_API_URL = '/api/auth/login';
const REFRESH_TOKEN_URL = '/api/v2/auth/token';
const SessionApiUrl = (sessionId: string) =>
  `/api/v2/account/sessions/${sessionId}`;

@Injectable()
export class UserAuthService {
  constructor(
    private readonly logger: LoggerService,
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly analyticsService: AnalyticsService,
    private readonly postHogService: PostHogService,
  ) {}

  isLoggedIn(): boolean {
    return !!this.localStorageService.getItem(AUTH_TOKEN_KEY);
  }

  refreshToken(currentRefreshToken: string) {
    const getTokenOptions = { refresh_token: currentRefreshToken };
    return this.http.post(REFRESH_TOKEN_URL, getTokenOptions).pipe(
      tap((response: { refresh_token?: string; token: string }) => {
        const accessToken = response.token;
        this.localStorageService.setItem(AUTH_TOKEN_KEY, accessToken);
        if (response.refresh_token) {
          this.localStorageService.setItem(
            REFRESH_TOKEN_KEY,
            response.refresh_token,
          );
        }
      }),
    );
  }

  logout(sessions?: any[]) {
    const currentSessionQuery = {
      refresh_token: this.localStorageService.getItem(REFRESH_TOKEN_KEY),
    };
    const currentSession = find(sessions, currentSessionQuery);
    const currentSessionId = get(currentSession, '_id');
    if (!currentSessionId) {
      this._onLogOut();
      return EMPTY;
    }
    return this.http
      .delete(SessionApiUrl(currentSessionId))
      .pipe(tap(() => this._onLogOut()))
      .subscribe();
  }

  login(credentials: LogInTokenCredentials) {
    return this.http.post(LOGIN_API_URL, credentials).pipe(
      tap((response: LoginResponse) => {
        this.localStorageService.setItem(AUTH_TOKEN_KEY, response.token);
        this.localStorageService.setItem(
          REFRESH_TOKEN_KEY,
          response.refresh_token,
        );
        this.analyticsService.sendAnalyticsIdentify(response.profile);
        if (response.isNewAccount) {
          this.analyticsService.setAnalyticsAlias(response.profile);
        }
      }),
    );
  }

  private _onLogOut() {
    this.logger
      .logInfo({ message: 'Logout' })
      .pipe(
        tap(async () => {
          await this.clearUserInfo();
          this._refreshView();
        }),
      )
      .subscribe();
  }

  async clearUserInfo() {
    this.localStorageService.removeItem(AUTH_TOKEN_KEY);
    this.localStorageService.removeItem(REFRESH_TOKEN_KEY);
    this.postHogService.reset();
    await this.analyticsService.clearAnalytics();
  }

  private _refreshView() {
    window.location.reload();
  }
}
