import { AccountGroupMembership } from '@libs/src/models/account.model';
import { Account } from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';
import { Program } from '@libs/src/models/program.model';

import { getMembership } from '../account/account.helpers';

import endsWith from 'lodash-es/endsWith';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import isEqual from 'lodash-es/isEqual';
import some from 'lodash-es/some';
import trim from 'lodash-es/trim';

const HIDDEN_HUB_STATUS = ['system'];
const USER_ACTIVE_STATUS = 'active';

export const DELETED_USER_ACCOUNT = {
  account: {
    first_name: $localize`:@@global.deletedUser:Deleted User`,
    full_name: $localize`:@@global.deletedUser:Deleted User`,
  },
};

export function filterVisibleGroups(
  groupMemberships: AccountGroupMembership[] = [],
) {
  return (groupMemberships || []).filter(
    (item: AccountGroupMembership) =>
      !includes(HIDDEN_HUB_STATUS, get(item, 'document.group_type')) &&
      isEqual('active', get(item, 'document.status')),
  );
}

export function isTenantAdmin(user: Account): boolean {
  return !!user?.isAdmin;
}

export function isAnyAdmin(user: Account): boolean {
  return isTenantAdmin(user) || isGroupAdmin(user);
}

export function isGroupAdmin(user: Account): boolean {
  return some(
    get(user, 'groups'),
    (group: AccountGroupMembership) => group.isAdmin,
  );
}

export function isAdminOfGroup(user: Account, group: Group): boolean {
  const membership = getMembership(user.groups, group?._id);
  return !!membership?.isAdmin;
}

export function isAdminOfPrograms(user: Account, group: Group) {
  const membership = getMembership(user.groups, group._id);
  return !!membership?.feature_admin?.programs;
}

export function isProgramManager(program: Program, user: Account) {
  if (!user?._id) {
    return false;
  }
  return (program.managers || []).some(
    (account) => user._id === ((account as any)?._id || account),
  );
}

export function isAnyAdminInProgram(
  user: Account,
  group: Group,
  program: Program,
) {
  return (
    isTenantAdmin(user) ||
    isAdminOfGroup(user, group) ||
    isAdminOfPrograms(user, group) ||
    isProgramManager(program, user)
  );
}

export function filterAdminGroups(
  groupMemberships: AccountGroupMembership[] = [],
): AccountGroupMembership[] {
  return groupMemberships.filter((item: AccountGroupMembership) =>
    get(item, 'isAdmin'),
  );
}

export function isAccountBlocked(blockedList: string[], accountId: string) {
  return includes(blockedList, accountId);
}

export function getAccountAbout(account: Account): string {
  return get(account, 'about', '');
}

export function getAccountFullName(account: Account): string {
  const firstName = get(account, 'first_name', '');
  const lastName = get(account, 'last_name', '');
  const fullName = trim(`${firstName} ${lastName}`);
  return get(account, 'full_name', fullName);
}

export function getAccountOrganizationName(account: Account): string {
  return get(account, 'experience[0].organization_name', '');
}

export function getAccountJobTitle(account: Account): string {
  return get(account, 'experience[0].job_title', '');
}

export function getAccountEducationClassOf(account: Account): string {
  return get(account, 'education[0].class_of', '');
}

export function getAccountEducationProgram(account: Account): string {
  return get(account, 'education[0].program', '');
}

export function getAccountEducationSchool(account: Account): string {
  return get(account, 'education[0].school', '');
}

export function getAccountGeoLocation(account: Account): string {
  return get(account, 'geo_location[0].address', '');
}

export function getAccountSlug(account: Account): string {
  return get(account, 'slug', '');
}

export function getAccountShortBio(account: Account): string {
  return get(account, 'short_bio', '');
}

export function getAccountWebsite(account: Account): string {
  return get(account, 'website', '');
}

export function isActiveUser(user: Account): boolean {
  return isEqual(USER_ACTIVE_STATUS, user.status);
}

export function isTenThousandCoffeesAdmin(user: Account): boolean {
  const userEmail = get(user, 'email');
  return (
    user.isAdmin &&
    (endsWith(userEmail, '@tenthousandcoffees.com') ||
      endsWith(userEmail, '@gets.coffee') ||
      endsWith(userEmail, '@10kc.com'))
  );
}

export function hasSignedUpViaOfficeHours(user: Account): boolean {
  return 'office_hours' === user.signup_method;
}

export function hasSignedUpViaPathway(user: Account): boolean {
  return 'pathway' === user.signup_method;
}

export function hasSignedUpViaDevelopmentProgram(user: Account): boolean {
  return 'development_program' === user.signup_method;
}

export function extractOpportunityIdFromOfficeHourSignUpMethodDetails(
  signUpMethodDetails = '',
) {
  return signUpMethodDetails.split('-')[0];
}

export function getUserPronouns(user: Account): string {
  return get(user, 'pronouns', '');
}

export function canExportHiddenSegments(user: Account) {
  return isAnyAdmin(user);
}
