import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { UserTraits } from '@segment/analytics-next';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, pairwise, take, tap } from 'rxjs/operators';

import {
  AUTH_TOKEN_KEY,
  LocalStorageService,
} from '@main-client/src/app/core/local-storage.service';

import type { AnalyticsEvent } from './analytics.types';
import { environment } from '@libs/src/environments/environment';
import type { Account } from '@libs/src/models/account.model';
import type { AuthToken } from '@libs/src/models/auth-token-model';
import { Tenant } from '@libs/src/tenant/tenant.interface';
import { decodeJwt } from '@libs/src/utilities/jwt.utilities';
import { getLocationOrigin } from '@libs/src/utilities/url.utilities';
import { APP_NAME, APP_VERSION } from '@main-client/src/app/app.metadata';
import type { IAppState } from '@main-client/src/app/app.state';

import pick from 'lodash-es/pick';

const BASE_EVENT_PROPS = {
  appName: APP_NAME,
  appVersion: APP_VERSION,
};

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private readonly segment = new AnalyticsBrowser();
  readonly pageViewEventName = 'Page Viewed';
  eventsToTrack = new ReplaySubject<AnalyticsEvent>();
  private additionalProperties: Record<string, unknown> = {};

  constructor(
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly store: Store<IAppState>,
  ) {
    this.segment.load(
      {
        writeKey: environment.segment.apiKey,
      },
      {
        storage: { stores: ['localStorage', 'memory'] },
      },
    );
  }

  initialize() {
    const tenantInfo = this.store.select('tenant').pipe(
      filter((tenant: Tenant) => !!tenant && 0 < Object.keys(tenant).length),
      map((tenant: Tenant) => ({
        tenantFriendlyName: tenant.friendly_name,
        tenantName: tenant.name,
        tenantPartnerType: tenant.partner_type,
      })),
    );
    this.trackRouterEvents();
    combineLatest([tenantInfo.pipe(take(1)), this.eventsToTrack]).subscribe(
      ([tenant, eventToTrack]) => {
        const snapshot = this.router.routerState.root.snapshot;
        this.segment.track(eventToTrack.eventName, {
          ...BASE_EVENT_PROPS,
          ...tenant,
          currentUrl: this.router.url,
          rawCurrentUrl: this.getRawUrlStructure(snapshot) || '/',
          ...this.additionalProperties,
          ...eventToTrack.properties,
        });
      },
    );
  }

  eventTrack(
    eventName: string,
    properties: AnalyticsEvent['properties'],
  ): void {
    this.eventsToTrack.next({ eventName, properties });
  }

  private trackRouterEvents() {
    const routerEventsSource = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    );
    routerEventsSource
      .pipe(
        take(1),
        tap((currentEvent) =>
          this.trackPageView(currentEvent.urlAfterRedirects),
        ),
      )
      .subscribe();
    routerEventsSource
      .pipe(
        pairwise(),
        tap(([previousEvent, currentEvent]) => {
          this.trackPageView(
            currentEvent.urlAfterRedirects,
            previousEvent.urlAfterRedirects,
          );
        }),
      )
      .subscribe();
  }

  trackPageView(currentUrl: string, fromUrl: string = null): void {
    const snapshot = this.router.routerState.root.snapshot;
    this.eventTrack(this.pageViewEventName, {
      currentUrl,
      fromUrl,
      queryParams: this.getPropertiesFromRouterTree(snapshot, 'queryParams'),
      rawCurrentUrl: this.getRawUrlStructure(snapshot) || '/',
      routerParams: this.getPropertiesFromRouterTree(snapshot, 'params'),
    });
  }

  getRawUrlStructure(routerSnapshot: ActivatedRouteSnapshot): string {
    if (!routerSnapshot.firstChild) {
      return '';
    }
    const path =
      routerSnapshot.firstChild.routeConfig &&
      routerSnapshot.firstChild.routeConfig.path;
    return `${path ? `/${path}` : ''}${this.getRawUrlStructure(
      routerSnapshot.firstChild,
    )}`;
  }

  private getPropertiesFromRouterTree(
    routerSnapshot: ActivatedRouteSnapshot,
    keyName: 'params' | 'queryParams',
  ): Record<string, unknown> {
    if (!routerSnapshot.firstChild) {
      return {};
    }
    const params = routerSnapshot.firstChild[keyName] || {};
    return {
      ...params,
      ...this.getPropertiesFromRouterTree(routerSnapshot.firstChild, keyName),
    };
  }

  clearAnalytics() {
    this.additionalProperties = {};
    return this.segment.reset();
  }

  async sendAnalyticsIdentify(account: Account) {
    const authToken = decodeJwt<AuthToken>(
      this.localStorage.getItem(AUTH_TOKEN_KEY),
    );
    const isSuperAdmin = !!authToken?.superAdmin;
    this.additionalProperties = {
      isSuperAdmin,
      assumedUser: isSuperAdmin
        ? pick(account, ['_id', 'full_name', 'email'])
        : undefined,
    };
    const userId = authToken?.superAdmin ?? account?._id;
    let traits: UserTraits;
    if (isSuperAdmin) {
      traits = {
        isSuperAdmin,
        name: `${userId} (Super Admin)`,
      };
    } else {
      traits = {
        aboutComplete: !!account.about,
        createdAt: account.created_at,
        email: account.email,
        firstName: account.first_name,
        geo_location: account.geo_location,
        hasPhoto: !!account.portrait_original_url,
        jobTitle: account.experience?.[0]?.job_title,
        lastName: account.last_name,
        name: account.full_name,
        organization: account.experience?.[0]?.organization_name,
        shortBioComplete: !!account.short_bio,
        signupMethod: account.signup_method,
        slug: account.slug,
        status: account.status,
        tenantUrl: getLocationOrigin(),
      };
    }
    return this.segment.identify(userId, traits);
  }

  async setAnalyticsAlias(account: Account) {
    const authToken = decodeJwt<AuthToken>(
      this.localStorage.getItem(AUTH_TOKEN_KEY),
    );
    const isSuperAdmin = !!authToken?.superAdmin;
    this.additionalProperties = {
      isSuperAdmin,
      assumedUser: isSuperAdmin
        ? pick(account, ['_id', 'full_name', 'email'])
        : undefined,
    };
    const analyticsUserId = (await this.segment.user()).id();
    if (!analyticsUserId) {
      const userId = authToken?.superAdmin ?? account?._id;
      await this.segment.alias(userId);
    }
  }
}
