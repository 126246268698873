export const localeCodes = {
  enUS: 'en-US',
  es: 'es',
  frCA: 'fr-CA',
  ja: 'ja',
  ko: 'ko',
} as const;
export const defaultLocaleCode = localeCodes.enUS;
export const supportedLocaleCodes = Object.values(localeCodes);
export type SupportedLocaleCode = (typeof supportedLocaleCodes)[number];
export const nonDefaultLocaleCodes = supportedLocaleCodes.filter(
  (localeCode) => localeCode !== defaultLocaleCode,
);
