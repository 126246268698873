import { Injectable } from '@angular/core';
import * as bowser from 'bowser';

import { LOCAL_STORAGE_KEYS } from '@libs/src/local-storage/local-storage-keys.enum';

import inRange from 'lodash-es/inRange';

declare global {
  interface Window {
    attachEvent?(type: string, listener: (event: any) => void): void;
  }
}

const isIE =
  bowser.msie && inRange(parseInt(bowser.version as string, 10), 9, 12);

@Injectable()
export class CrossTabListenerService {
  addListeners() {
    if (window.addEventListener) {
      window.addEventListener(
        'storage',
        (event) => this.handleStorageEvent(event),
        false,
      );
      return;
    }

    if (window.attachEvent) {
      window.attachEvent('onstorage', (event) =>
        this.handleStorageEvent(event),
      );
    }
  }

  private handleStorageEvent(event: any) {
    if (LOCAL_STORAGE_KEYS.auth === event.key) {
      const isLoginEvent = event.newValue && !event.oldValue;
      const isLogoutEvent = event.oldValue && !event.newValue;

      if (isIE && isLogoutEvent) {
        window.location.reload();
        return;
      }

      if (isIE) {
        return;
      }

      if (isLogoutEvent || isLoginEvent) {
        window.location.reload();
      }
    }
  }
}
