import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import {
  bufferTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';

import { LocalStorageService } from '@main-client/src/app/core/local-storage.service';
import { UserService } from '@main-client/src/app/user/user.service';

import { UserIdleTimeoutDialogComponent } from './user-idle-timeout-dialog/user-idle-timeout-dialog.component';

export const ACTIVITY_STORAGE_KEY = 'user_last_active';

export const enum State {
  Idle = 1,
  Active,
  Prompt,
  Expired,
}

interface Config {
  pingInterval: number;
  timeout: number;
  timerResolution: number;
  warningTime: number;
}

const DEFAULT_CONFIG = {
  pingInterval: 10_000,
  timerResolution: 1_000,
  warningTime: 60_000,
} as const satisfies Partial<Config>;

@Injectable()
export class UserIdleTimeoutService {
  private subscriptions: Subscription[] = [];
  private timeoutDialog?: MatDialogRef<UserIdleTimeoutDialogComponent>;
  private state: State = State.Active;
  private nextPing?: number;
  private deadline: number;
  private warning: number;
  private config: Config;

  constructor(
    private readonly dialog: MatDialog,
    private readonly localStorageService: LocalStorageService,
    private readonly userService: UserService,
  ) {
    this.storageCleanup$.subscribe();
  }

  setConfig(config: Partial<Config> & Pick<Config, 'timeout'>) {
    this.config = {
      ...DEFAULT_CONFIG,
      ...config,
    };
  }

  stop() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  start() {
    this.stop();
    this.updateTimeouts(Date.now());
    this.subscriptions.push(
      this.initWatcher().subscribe(),
      this.otherTabActivity$.subscribe(this.onOtherTabActivity.bind(this)),
    );
  }

  private readonly storageCleanup$ = merge(
    of(this.userService.isLoggedIn),
    this.userService.isLoggedIn$(),
  ).pipe(
    filter((isLoggedIn) => !isLoggedIn),
    tap(() => {
      this.stop();
      this.localStorageService.removeItem(ACTIVITY_STORAGE_KEY);
    }),
  );

  private readonly activityEvents$ = merge(
    fromEvent(window, 'mousemove'),
    fromEvent(window, 'wheel'),
    fromEvent(window, 'resize'),
    fromEvent(document, 'keydown'),
    fromEvent(document, 'touch'),
    fromEvent(document, 'touchstart'),
  );

  private readonly otherTabActivity$ = this.localStorageService
    .getItem$(ACTIVITY_STORAGE_KEY, { excludeSelf: true })
    .pipe(
      filter((value: string) => !!value),
      map((value: string) => parseInt(value, 10)),
    );

  private updateTimeouts(now: number = Date.now()) {
    this.deadline = now + this.config.timeout;
    this.warning = this.deadline - this.config.warningTime;
  }

  private eventLoop(events: Event[]): State | undefined {
    const hasActivity = !!events.length;
    const now = Date.now();
    if (this.deadline < now) {
      return State.Expired;
    }
    if (this.state === State.Prompt) {
      return;
    }
    if (!hasActivity && this.warning < now) {
      return State.Prompt;
    }
    if (hasActivity) {
      if ((this.nextPing ?? 0) <= now) {
        this.ping(now);
      }
      this.updateTimeouts(now);
      return State.Active;
    }
    return State.Idle;
  }

  private onOtherTabActivity(timestamp: number) {
    this.updateTimeouts(timestamp);
    if (this.state === State.Prompt) {
      this.timeoutDialog?.close(true);
    }
    this.state = State.Idle;
  }

  private initWatcher() {
    return this.activityEvents$.pipe(
      bufferTime(this.config.timerResolution),
      map((events) => this.eventLoop(events)),
      filter((state) => !!state),
      distinctUntilChanged(),
      tap((state) => {
        this.state = state;
        if (this.state === State.Prompt) {
          this.openTimeoutDialog();
        }
        if (this.state === State.Expired) {
          this.userService.logout();
        }
      }),
    );
  }

  private ping(now: number) {
    this.nextPing = now + this.config.pingInterval;
    this.localStorageService.setItem(ACTIVITY_STORAGE_KEY, now.toString());
  }

  private openTimeoutDialog() {
    this.timeoutDialog = this.dialog.open<
      UserIdleTimeoutDialogComponent,
      { timeout: number },
      boolean
    >(UserIdleTimeoutDialogComponent, { data: { timeout: this.deadline } });
    this.timeoutDialog
      .afterClosed()
      .pipe(
        tap((continueSession) => {
          delete this.timeoutDialog;
          this.state = continueSession ? State.Active : State.Expired;
        }),
      )
      .subscribe();
  }
}
