import { Injectable } from '@angular/core';

import { LocaleSettingsService } from '@main-client/src/app/core/locale-settings.service';
import { WindowRefService } from '@main-client/src/app/window-ref.service';

import { locales } from '@libs/src/locale/locale.helper';
import { Locale } from '@libs/src/locale/locale.interface';
import {
  defaultLocaleCode,
  SupportedLocaleCode,
} from '@libs/src/locale/locale.shared';
import { Account } from '@libs/src/models/account.model';
import { Group } from '@libs/src/models/group.model';

import find from 'lodash-es/find';
import includes from 'lodash-es/includes';

@Injectable({
  providedIn: 'root',
})
export class LocaleManagerService {
  private locales = locales;

  constructor(
    private readonly localeSettingsService: LocaleSettingsService,
    private readonly windowRefService: WindowRefService,
  ) {}

  getLocaleForUser(user: Account): SupportedLocaleCode {
    const userSelectedLocale = user?.locale_config?.locale_selected;
    if (userSelectedLocale) {
      return userSelectedLocale;
    }
    const cookieSelectedLocale =
      this.localeSettingsService.getLocaleCookie()?.locale_selected;
    if (cookieSelectedLocale) {
      return cookieSelectedLocale;
    }
    const browserSelectedLocale =
      this.windowRefService.nativeWindow.navigator.language;
    const validatedBrowserSelectedLocale = find(
      this.locales,
      ({ code }) =>
        code.toLowerCase() === browserSelectedLocale.toLocaleLowerCase(),
    )?.code;
    if (validatedBrowserSelectedLocale) {
      return validatedBrowserSelectedLocale;
    }
    return defaultLocaleCode;
  }

  getLocaleString(
    localeMap: { [translationUnit: string]: { [key: string]: string } },
    message: string,
    translationUnits: string[],
  ) {
    return (
      find(
        localeMap,
        (unitLocaleMap, translationUnit) =>
          includes(translationUnits, translationUnit) &&
          !!unitLocaleMap[message],
      )?.[message] || message
    );
  }

  doesGroupSupportUserLocale(user: Account, group: Group) {
    const userLocaleCode = this.getLocaleForUser(user);
    if (false === group?.features_enabled?.localization) {
      return userLocaleCode === defaultLocaleCode;
    }
    const groupSupportedLocaleCodes = group?.supported_locales;
    return (
      !groupSupportedLocaleCodes?.length ||
      groupSupportedLocaleCodes.includes(userLocaleCode)
    );
  }

  getSupportedLocalesForGroup(group: Group): Locale[] {
    if (group?.supported_locales?.length) {
      return group.supported_locales.map((localeCode) =>
        locales.find((locale) => locale.code === localeCode),
      );
    }
    return [...locales];
  }

  getSupportedNonDefaultLocalesForGroup(group: Group): Locale[] {
    const supportedLocales = this.getSupportedLocalesForGroup(group);
    return supportedLocales.filter(
      (locale) => locale.code !== defaultLocaleCode,
    );
  }
}
