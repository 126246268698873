import { isAfter, isPast } from 'date-fns';

import { Account } from '@libs/src/models/account.model';
import { PARTICIPANT_STATUSES } from '@libs/src/models/event-submissions-dialog.model';
import { Group } from '@libs/src/models/group.model';
import { TENANT_PARTNER_TYPES } from '@libs/src/tenant/tenant-partner-types.enum';
import { EVENT_SUBMISSION_SELECTED_STATUS } from '@main-client/src/app/events/event-submission-selected-status.enum';
import { EVENT_SUBMISSION_STATUS } from '@main-client/src/app/events/event-submission-status.enum';
import { SUBMISSION_FILTER_QUERIES } from '@main-client/src/app/events/event-submissions-view/event-submissions.helper';
import { OPPORTUNITY_STATUS } from '@main-client/src/app/opportunity/opportunity-status.enum';
import { OPPORTUNITY_TYPES } from '@main-client/src/app/opportunity/opportunity-types.enum';
import {
  Opportunity,
  OpportunityIsUserInvited,
  OpportunityState,
  OpportunitySubmission,
  Participant,
} from '@main-client/src/app/opportunity/opportunity.model';

import { isAdminOfGroup, isTenantAdmin } from '../user/user.helpers';

import cloneDeep from 'lodash-es/cloneDeep';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import isObject from 'lodash-es/isObject';
import mapValues from 'lodash-es/mapValues';
import omit from 'lodash-es/omit';
import set from 'lodash-es/set';
import some from 'lodash-es/some';

export const getOpportunityErrorMessages = {
  404: `We can't find the office hour you're looking for`,
};

export function findOpportunityStateById(
  states: OpportunityState[],
  id: string,
) {
  return find(states, (state: OpportunityState) =>
    isEqual(state.document._id, id),
  );
}

export function findOpportunityStateBySlug(
  states: OpportunityState[],
  slug: string,
) {
  if (isEmpty(states)) {
    return;
  }
  return find(states, (state: OpportunityState) =>
    isEqual(state.document.slug, slug),
  );
}

export function findStateIndexById(states: any[], id: string): number {
  if (!id) {
    return;
  }
  return findIndex(states, (state: any) => isEqual(state.document._id, id));
}

export function findStateIndexBySlug(states: any[], slug: string): number {
  if (!slug) {
    return;
  }
  return findIndex(states, (state: any) => isEqual(state.document.slug, slug));
}

export function getConfirmedOpportunitySubmissions(
  submissions: OpportunitySubmission[] = [],
  opportunityType: string,
) {
  if (isEmpty(submissions)) {
    return [];
  }
  return submissions
    .filter(SUBMISSION_FILTER_QUERIES.active)
    .filter((SUBMISSION_FILTER_QUERIES as any)[opportunityType].confirmed);
}

export function getWaitlistedOpportunitySubmissions(
  submissions: OpportunitySubmission[] = [],
  opportunityType: string,
) {
  if (isEmpty(submissions)) {
    return [];
  }
  return submissions
    .filter(SUBMISSION_FILTER_QUERIES.active)
    .filter((SUBMISSION_FILTER_QUERIES as any)[opportunityType].waitlisted);
}

export function getCancelledOpportunitySubmissions(
  submissions: OpportunitySubmission[] = [],
) {
  if (isEmpty(submissions)) {
    return [];
  }
  return submissions.filter(SUBMISSION_FILTER_QUERIES.cancelled);
}

export function sanitizeGetParams(params: any) {
  return mapValues(params, (param: any) => {
    return isArray(param) ? param : `${param}`;
  });
}

export function updateOpportunitySubmissionForUser(
  opportunity: Opportunity,
  submission: OpportunitySubmission,
  user: Account,
) {
  const updatedUserSubmissionIndex = findIndex(
    opportunity.submissions,
    (item: OpportunitySubmission) => {
      return (
        isEqual(item, submission._id) ||
        isEqual(get(item, 'accountId._id', item.accountId), user._id)
      );
    },
  );
  const sanitizedSubmission = {
    ...(0 <= updatedUserSubmissionIndex
      ? opportunity.submissions[updatedUserSubmissionIndex]
      : {}),
    ...(isObject(submission.accountId)
      ? submission
      : omit(submission, 'accountId')),
  };
  const submissionsClone = cloneDeep(opportunity.submissions || []);
  const updatedSubmissions =
    0 <= updatedUserSubmissionIndex
      ? set(submissionsClone, updatedUserSubmissionIndex, sanitizedSubmission)
      : set(submissionsClone, submissionsClone.length, {
          accountId: user,
          ...sanitizedSubmission,
        });
  return {
    ...opportunity,
    submissions: updatedSubmissions,
    user_submission: submission,
  };
}

export function confirmOpportunitySubmissions(
  opportunity: Opportunity,
  confirmedAttendeeIds: Account['_id'][],
) {
  return {
    ...opportunity,
    rsvp_list_confirmed: true,
    submissions: opportunity.submissions.map(
      (submission: OpportunitySubmission) => {
        const isConfirmed = (confirmedAttendeeIds ?? []).includes(
          submission?.accountId?._id ?? String(submission.accountId),
        );
        return {
          ...submission,
          selected_status: isConfirmed
            ? EVENT_SUBMISSION_SELECTED_STATUS.selected
            : submission.selected_status,
          status: isConfirmed
            ? EVENT_SUBMISSION_STATUS.active
            : submission.status,
        };
      },
    ),
  };
}

export function unselectOpportunitySubmissions(
  opportunity: Opportunity,
  deletedAttendeeIds: Account['_id'][],
) {
  return {
    ...opportunity,
    submissions: opportunity.submissions.map(
      (submission: OpportunitySubmission) => {
        const isDeleted = (deletedAttendeeIds ?? []).includes(
          submission?.accountId?._id ?? String(submission.accountId),
        );
        return {
          ...submission,
          status: isDeleted
            ? EVENT_SUBMISSION_STATUS.inactive
            : submission.status,
        };
      },
    ),
  };
}

export function updateOpportunityInList(
  opportunities: Opportunity[],
  opportunity: Opportunity,
) {
  const opportunitiesClone = [...opportunities];
  const index = findIndex(opportunities, (item: Opportunity) =>
    isEqual(opportunity._id, item._id),
  );
  if (0 <= index) {
    opportunitiesClone[index] = {
      ...opportunitiesClone[index],
      ...opportunity,
    };
  }
  return opportunitiesClone;
}

export function isOpportunityTypeEvent(opportunity: Opportunity) {
  return includes(
    [OPPORTUNITY_TYPES.event, OPPORTUNITY_TYPES.exclusive],
    opportunity.opportunity_type,
  );
}

export function isUserFeaturedAccountForOpportunity(
  user: Account,
  opportunity: Opportunity,
): boolean {
  return !!find(opportunity.featured_accounts, (featuredAccount: Account) => {
    const featuredAccountId = get(featuredAccount, '_id', featuredAccount);
    return isEqual(user._id, featuredAccountId);
  });
}

export function isConfirmedRsvp(
  submission: OpportunitySubmission,
  opportunityType: string,
) {
  return (
    !isEmpty(submission) &&
    !isEmpty(getConfirmedOpportunitySubmissions([submission], opportunityType))
  );
}

export function isWaitlistedRsvp(
  submission: OpportunitySubmission,
  opportunityType: string,
) {
  return (
    !isEmpty(submission) &&
    !isEmpty(getWaitlistedOpportunitySubmissions([submission], opportunityType))
  );
}

export function isHost(user: Account, opportunity: Opportunity): boolean {
  return some(opportunity.featured_accounts, (account: Account) => {
    const accountId = get(account, '_id', account);
    return isEqual(accountId, user._id);
  });
}

export function isEditorAccount(
  user: Account,
  opportunity: Opportunity,
): boolean {
  return some(opportunity.editor_accounts, (account: Account) => {
    return account._id === user._id;
  });
}

export function canEditEvent(user: Account, opportunity: Opportunity): boolean {
  return isEditorAccount(user, opportunity) || isHost(user, opportunity);
}

export function canRsvp(opportunity: Opportunity) {
  const now = new Date(Date.now());
  if (OPPORTUNITY_STATUS.active !== opportunity.status) {
    return false;
  }
  if (!opportunity.submission_instructions?.[0]) {
    return false;
  }
  return isAfter(new Date(opportunity.rsvp_end_date), now);
}

export function canUserLeaveParticipantFeedback(
  event: Opportunity,
  userSubmission: OpportunitySubmission,
) {
  const isPastDate = isPast(new Date(event.event_end_date));
  const isUserConfirmedSubmission = isConfirmedRsvp(
    userSubmission,
    event.opportunity_type,
  );
  return isUserConfirmedSubmission && isPastDate;
}

export function canUserLeaveHostFeedback(event: Opportunity, user: Account) {
  const isPastDate = isPast(new Date(event.event_end_date));
  const isUserHost = isHost(user, event);
  return isUserHost && isPastDate;
}

export function isCustomFeedbackQuestionsEnabled(group: Group): boolean {
  return [
    TENANT_PARTNER_TYPES.enterprise,
    TENANT_PARTNER_TYPES.uncategorized,
  ].includes(group?.partner_type);
}

export function isAllowedToViewEvent(
  event: OpportunityIsUserInvited,
  user: Account,
  group: Group,
) {
  const userSubmission =
    event.user_submission?.accountId === user._id
      ? event.user_submission
      : undefined;
  return (
    !event.private ||
    isTenantAdmin(user) ||
    isAdminOfGroup(user, group) ||
    canEditEvent(user, event) ||
    event.is_user_invited ||
    isConfirmedRsvp(userSubmission, event.opportunity_type) ||
    isWaitlistedRsvp(userSubmission, event.opportunity_type)
  );
}

export function getParticipantsFromSubmissions(
  submissions: OpportunitySubmission[],
): Participant[] {
  return submissions.map((submission) => {
    const participantStatus =
      getParticipantStatusForInactiveAttendees(submission);
    return {
      ...(submission.accountId?._id
        ? submission.accountId
        : { _id: String(submission.accountId) }),
      ...(participantStatus && { participantStatus }),
      submissionResponse: submission.submissions?.[0],
    };
  });
}

export function getParticipantStatusForInactiveAttendees(
  submission: OpportunitySubmission,
): PARTICIPANT_STATUSES | undefined {
  if (EVENT_SUBMISSION_STATUS.active === submission.status) {
    return;
  }
  const isUnknownModifier = !submission.last_modified_by;
  if (isUnknownModifier || !submission.accountId?._id) {
    return PARTICIPANT_STATUSES.cancelled;
  }
  return submission.accountId?._id === submission.last_modified_by
    ? PARTICIPANT_STATUSES.cancelled
    : PARTICIPANT_STATUSES.removed;
}

export function getParticipantStatusForNewAttendees(
  opportunity: Opportunity,
  newAttendeeId: string,
): PARTICIPANT_STATUSES | undefined {
  if (
    opportunity.featured_accounts?.some(
      (featuredAccount) => String(featuredAccount._id) === newAttendeeId,
    )
  ) {
    return PARTICIPANT_STATUSES.host;
  }
  if (
    opportunity.submissions.some(
      (submission) => String(submission.accountId._id) === newAttendeeId,
    )
  ) {
    return PARTICIPANT_STATUSES.registered;
  }
}
