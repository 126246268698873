import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { ZendeskBackend } from '@libs/src/zendesk/zendesk.backend';
import { LocaleManagerService } from '@main-client/src/app/locale-manager/locale-manager.service';
import { WindowRefService } from '@main-client/src/app/window-ref.service';

import type { SupportedLocaleCode } from '@libs/src/locale/locale.shared';
import { localeCodes } from '@libs/src/locale/locale.shared';
import type { Account } from '@libs/src/models/account.model';

const ZENDESK_AUTH_URL = 'https://10kc.zendesk.com/access/jwt';
export const DEFAULT_ZENDESK_DESTINATION =
  'https://support.tenthousandcoffees.com/';
export const AGENT_SIGNIN_URL =
  'https://10kc.zendesk.com/auth/v2/login/signin?role=agent';

type MessengerCallback = (token: string) => void;

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  currentUserLocale?: SupportedLocaleCode;
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly localeManagerService: LocaleManagerService,
    private readonly windowService: WindowRefService,
    private readonly zendeskBackend: ZendeskBackend,
  ) {
    if (!this.isBrowser()) {
      return;
    }
    this.hideZendesk();
    this.windowService.nativeWindow.zE('messenger:on', 'close', () => {
      this.hideZendesk();
    });
  }

  private isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  isHelpCenterUrl(url: string): boolean {
    return /^https?:\/\/support\.tenthousandcoffees\.com/i.test(url);
  }

  stripLocale(url: string) {
    const regex = new RegExp(
      `support\\.tenthousandcoffees\\.com/hc/(${Object.values(localeCodes).join('|')})`,
      'i',
    );
    return url.replace(regex, 'support.tenthousandcoffees.com/hc');
  }

  showZendesk(): void {
    if (this.currentUserLocale) {
      this.windowService.nativeWindow.zE(
        'messenger:set',
        'locale',
        this.currentUserLocale,
      );
    }
    this.windowService.nativeWindow.zE('messenger', 'show');
    this.windowService.nativeWindow.zE('messenger', 'open');
  }

  hideZendesk(): void {
    this.windowService.nativeWindow.zE('messenger', 'hide');
  }

  updateUserProperties(user: Account) {
    this.currentUserLocale = this.localeManagerService.getLocaleForUser(user);
    return this.zendeskBackend
      .authenticate()
      .pipe(
        take(1),
        tap(({ token }) => {
          this.windowService.nativeWindow.zE(
            'messenger',
            'loginUser',
            (callback: MessengerCallback) => callback(token),
          );
        }),
        catchError(() => EMPTY),
      )
      .subscribe();
  }

  postZendeskAuth(token: string, returnTo: string, target: '_blank' | '_self') {
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute(
      'action',
      `${ZENDESK_AUTH_URL}?return_to=${encodeURIComponent(returnTo)}`,
    );
    form.setAttribute('target', target);
    const jwtInput = document.createElement('input');
    jwtInput.setAttribute('name', 'jwt');
    jwtInput.setAttribute('value', token);
    form.append(jwtInput);
    document.body.append(form);
    form.submit();
    form.remove();
  }

  getSsoToken() {
    return this.zendeskBackend.fetchSsoToken();
  }

  navigate(destinationUrl: string) {
    destinationUrl = this.stripLocale(destinationUrl);
    return this.getSsoToken()
      .pipe(
        take(1),
        tap(({ token }) => {
          if (token) {
            this.postZendeskAuth(token, destinationUrl, '_blank');
          } else {
            window.open(destinationUrl);
          }
        }),
        catchError(() => {
          window.open(destinationUrl);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  shutdown(): void {
    if (!this.isBrowser()) {
      return;
    }
    return this.windowService.nativeWindow.zE('messenger', 'close');
  }
}
